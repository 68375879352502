@import '~normalize.css';

/* jost-100 - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 100;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-100.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-100.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-100.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-100.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-200 - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 200;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-200.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-200.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-200.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-200.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-300 - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-300.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-regular - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-regular.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-500 - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-500.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-600 - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 600;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-600.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-700 - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-700.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-800 - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 800;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-800.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-900 - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 900;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-900.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-100italic - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 100;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-100italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-100italic.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-100italic.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-200italic - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 200;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-200italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-200italic.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-200italic.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-300italic - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 300;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-300italic.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-italic - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 400;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-italic.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-500italic - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 500;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-500italic.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-500italic.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-600italic - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 600;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-600italic.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-600italic.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-700italic - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 700;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-700italic.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-800italic - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 800;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-800italic.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-800italic.svg#Jost') format('svg'); /* Legacy iOS */
}

/* jost-900italic - latin-ext_latin */
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 900;
  src: url('src/assets/fonts/jost-v9-latin-ext_latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('src/assets/fonts/jost-v9-latin-ext_latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('src/assets/fonts/jost-v9-latin-ext_latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-900italic.woff') format('woff'), /* Modern Browsers */ url('src/assets/fonts/jost-v9-latin-ext_latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('src/assets/fonts/jost-v9-latin-ext_latin-900italic.svg#Jost') format('svg'); /* Legacy iOS */
}

html, body {
  font-family: 'Jost', sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

* {
  -webkit-font-smoothing: antialiased;
}

.button {
  background-color: #3D3D3D;
  border: 0;
  color: white;
  font-weight: 400;
}

.button:hover {
  background-color: rgb(179, 179, 179);
}

.button:active {
  background-color: #6F4950;
}

.input-field {
  color: #452E32;
  border: none;
  background: #FBFBFB;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

.input-field:focus {
  outline: none;
}

::placeholder {
  opacity: 0.5;
}

#radio-policy, #radio-terms, #radio-aszf-footer, #radio-policy-footer, #different-billing-address, #company-billing-address, #sub, #shipping1, #shipping2, 
#simplepay, #cod, #simplepay-policy, #shipping-radio {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%) !important;
  opacity: 100% !important;
}


/* ///////////    Globális class-ek  ///////////   */
.anja-alert-title {
  font-size: 20px !important;
  font-weight: 300 !important;
  color: #452E32 !important;
}
.anja-alert-text {
  font-size: 1rem !important;
  font-weight: 300 !important;
  color: #452E32 !important;
}
.anja-alert-button {
  background: #3D3D3D !important;
  color: white !important;
  border-radius: 0px !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.anja-alert-button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.anja-cancel-button {
  background: #E7B2AB !important;
  color: white !important;
  border-radius: 0px !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.anja-cancel-button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.index-arrow {
  margin-left: 20px;
  margin-right: 20px;
}

.title-small {
  font-weight: 300;
}

.title-medium {
  font-weight: 600;
  color: #452E32;
}

.title-large {
  text-align: center;
  font-weight: 400;
}

.title-large-left-align {
  text-align: left;
}

.paragraph-text {
  text-align: center;
  font-weight: 300;
  line-height: 20px;
  color: #452E32;
}

.line {
  border: 2px solid #452E32;
}

.thin-line {
  opacity: 0.2;
  border: 1px solid #00000034;
}

.category {
  font-weight: 300;
  color: #452E32;
  border-bottom: 1px solid #6F4950;
}

.selected-category {
  border-bottom: 2px solid #452E32;
  font-weight: 600;
}

.window {
  box-shadow: 10px 10px 34px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 110px 0;
  background-color: white;
  height: fit-content;
}

.window-header {
  background-color: #E7B2AB;
}

.window-title {
  font-weight: 500;
  color: white;
}

.input-span {
  color: #452E32;
  font-weight: 300;
}
 input {
   cursor: pointer !important;
 }

.input-field {
  background-color: #FBFBFB !important;
}

.underlined {
  font-weight: 600;
  color: #6F4950;
  width: auto;
  text-decoration: underline;
  cursor: pointer;
}

.disabled-button {
  pointer-events: unset;
  cursor: not-allowed !important;
}

.snackbar {
  display: flex;
  left: 0;
  right: 0;
  margin: auto;
  min-width: 250px; /* Set a default minimum width */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 9999; /* Add a z-index if needed */
  bottom: 0; /* 30px from the bottom */
}

/*#snackbar.show {*/
/*  visibility: visible;*/
/*  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;*/
/*  animation: fadein 0.5s, fadeout 0.5s 2.5s;*/
/*}*/

/*@-webkit-keyframes fadein {*/
/*  from {bottom: 0; opacity: 0;}*/
/*  to {bottom: 30px; opacity: 1;}*/
/*}*/

/*@keyframes fadein {*/
/*  from {bottom: 0; opacity: 0;}*/
/*  to {bottom: 30px; opacity: 1;}*/
/*}*/

/*@-webkit-keyframes fadeout {*/
/*  from {bottom: 30px; opacity: 1;}*/
/*  to {bottom: 0; opacity: 0;}*/
/*}*/

/*@keyframes fadeout {*/
/*  from {bottom: 30px; opacity: 1;}*/
/*  to {bottom: 0; opacity: 0;}*/
/*}*/

@media only screen and (max-width: 575px) {
  .button {
    width: 34%;
    height: 38px;
    font-size: 4vw;
  }

  .input-field {
    padding: 9px 18px 9px 18px;
    font-size: 3vw;
  }

  .title-small {
    font-size: 3vw;
  }

  .title-medium {
    font-size: 7vw;
  }

  .title-large {
    padding-top: 3%;
    font-size: 8vw;
    color: #452E32;
  }

  .paragraph-text {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 4vw;
  }

  .line {
    border: 1px solid #452E32;
    display: block;
    left: 0;
    right: 0;
    margin: auto;
    width: 15%;
  }

  .input-span {
    font-size: 3.5vw;
  }

  .window {
    padding: 0;
    width: 85%;
  }

  .window-title {
    font-size: 6vw;
    padding: 4% 7%;
  }

  .window-top {
    padding-left: 7%;
    padding-right: 7%;
  }

  .window-bottom {
    padding-left: 7%;
    padding-right: 7%;
    margin-top: 10%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .button {
    width: 34%;
    height: 38px;
    font-size: 2.5vw;
  }

  .input-field {
    padding: 9px 18px 9px 18px;
    font-size: 2vw;
  }

  .title-small {
    font-size: 14px
  }

  .title-large {
    padding-top: 3%;
    font-size: 6vw;
    color: #452E32;
  }

  .paragraph-text {
    margin-left: 10%;
    margin-right: 10%;
    line-height: 22px;
    font-size: 3vw;
  }

  .line {
    border: 1px solid #452E32;
    display: block;
    left: 0;
    right: 0;
    margin: auto;
    width: 15%;
  }

  .input-span {
    font-size: 2.5vw;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .button {
    width: 135px;
    height: 30px;
    font-size: 13px;
  }

  .input-field {
    padding: 9px 18px 9px 18px;
    font-size: 16px;
  }

  .title-small {
    font-size: 14px
  }

  .title-large {
    padding-top: 35px;
    font-size: 35px;
    color: #452E32;
  }

  .paragraph-text {
    line-height: 22px;
    width: 620px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }

  .line {
    border: 1px solid #452E32;
    display: block;
    left: 0;
    right: 0;
    margin: auto;
    width: 45px;
  }

  .input-span {
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .button {
    width: 209px;
    height: 50px;
    font-size: 17px;
  }

  .input-field {
    padding: 9px 18px 9px 18px;
    font-size: 16px;
  }

  .title-small {
    font-size: 16px
  }

  .title-large {
    padding-top: 35px;
    font-size: 45px;
    color: #452E32;
  }

  .paragraph-text {
    line-height: 29px;
    width: 525px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
  }

  .line {
    border: 1px solid #452E32;
    display: block;
    left: 0;
    right: 0;
    margin: auto;
    width: 45px;
  }

  .input-span {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  .button {
    width: 209px;
    height: 50px;
    font-size: 17px;
  }

  .input-field {
    padding: 9px 18px 9px 18px;
    font-size: 23px;
  }

  .title-small {
    font-size: 16px;
  }

  .title-large {
    padding-top: 35px;
    font-size: 45px;
    color: #452E32;
  }

  .paragraph-text {
    line-height: 29px;
    width: 525px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    color: #452E32;
  }

  .line {
    border: 1px solid #452E32;
    display: block;
    left: 0;
    right: 0;
    margin: auto;
    width: 72px;
  }

  ::placeholder{
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
  }

  .input-span {
    font-size: 20px;
  }
}

.white {
  color: white;
}

@media only screen and (min-width: 1279px) and (max-width: 1600px) {

  .title-large {
    font-size: 38px !important;
  }

  .paragraph-text {
    font-size: 16px !important;
  }

  #value-img-container {
    max-width: 875px !important;
  }

  .thoughts{
    font-size: 16px !important;
  }

  .woman-flower-larger {
    top: 56% !important;
    width: 63% !important;
  }

  .input-span {
    font-size: 18px !important;
  }

  .product-description-wrapper {
    margin-top: 90px !important;
  }

  .magic-text {
    font-size: 38px !important;
  }

  .picture-text {
    font-size: 20px !important;
  }



}
@media only screen and (min-width: 1600px){
  .woman-flower-larger {
    top: 55% !important;

  }
}


/*Retina stuff*/
@media
(-webkit-min-device-pixel-ratio: 1.5),
(min-resolution: 96dpi){
  .space-container {
    max-width: 1920px;
    margin: auto;
  }
}




@media screen and (max-width:680px) {
  #big-canvas {
    display: flex;
    flex-direction: column;
  }

  #big-canvas #left-canvas {
    width: 100%;
  }

  #big-canvas #right-canvas {
    width: 100%;
  }

  #big-canvas #left-canvas #searchinput{
    width: 100%;
    margin-left: 0px;
  }
}